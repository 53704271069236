import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Logo from "../../components/Checkout/Logo";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ListGroup } from "react-bootstrap";
import {
  ArrowClockwise,
  Calendar3,
  CaretDownFill,
  CaretUpFill,
  CheckLg,
  Coin,
  InfoCircle,
  StarFill,
  XLg,
} from "react-bootstrap-icons";
import { formatPrice } from "../../utils/formatValues";

import PricingCalculator from "../../utils/PricingCalculator";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

import PaymentForm from "../../components/Checkout/PaymentForm";
import { extractUTM } from "../../utils/extractURLParams";
import BasicSpinner from "../../components/UI/Spinner";
import { removeSpecialCharacters } from "../../utils/removeSpecialCharacters";

import "./custom.css";

const SingleCheckout = () => {
  const { store, price_slug } = useParams();
  if (!store || !price_slug) window.location.replace("/");

  // get params on url
  // example, coupon_code, utm_xxx and so on...
  const searchParams = new URLSearchParams(document.location.search);

  const [taxSettings, setTaxSettings] = useState({
    tax_rate: 0,
    is_tax_inclusive: false,
  });

  const [selectedPlans, setSelectedPlans] = useState([]);
  const [appliedCoupons, setAppliedCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [isAddingCoupon, setIsAddingCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingButton, setIsShowingButton] = useState(false);

  const fetchCouponCodes = async (coupon_code, plan_ids) => {
    if (!coupon_code) return;

    setIsAddingCoupon(true);
    const response = await fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/api/v1/stores/${store}/coupons/${removeSpecialCharacters(coupon_code)}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          plans: plan_ids,
        }),
      }
    );

    const { error, payloads } = await response.json();
    if (error) {
      const { message } = error;
      NotificationManager.error(message);
    }

    if (!payloads.length) NotificationManager.warning("Invalid coupon code");

    setAppliedCoupons(payloads);
    setIsAddingCoupon(false);
    setCouponCode(removeSpecialCharacters(coupon_code));
  };

  const initiateTracking = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/${store}/team_settings`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const { error, payloads } = await response.json();
    if (error) return;

    const { fb_pixel, fb_pixel_test_code, google_analytics_id } = payloads;

    let metadata = {};

    if (Boolean(fb_pixel_test_code)) {
      metadata.test_event_code = fb_pixel_test_code;
    }

    if (Boolean(fb_pixel)) {
      ReactPixel.init(fb_pixel);
      ReactPixel.track("InitiateCheckout", metadata);
    }

    if (Boolean(google_analytics_id)) {
      ReactGA.initialize(google_analytics_id);

      ReactGA.event({
        category: "Ecommerce",
        action: "Begin Checkout",
      });
    }
  };

  const removeNotPermittedCoupons = (ids = []) => {
    setAppliedCoupons((prevState) =>
      prevState.filter((item) => !ids.includes(item?.coupon_id))
    );
  };

  // ✅ Get price plans
  // ✅ Get coupon codes
  // ✅ Fire facebook pixels
  // ✅ Get all active payment accounts
  useEffect(() => {
    const fetchPricePlans = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/${store}/plans/${price_slug}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const { error, payloads } = await response.json();

      // for order summary and payment information
      let selected_plans = [];

      if (payloads) {
        selected_plans.push(payloads);
      }

      setSelectedPlans(selected_plans);

      if (error) {
        const { message } = error;
        NotificationManager.error(message);
        return;
      }

      return selected_plans;
    };

    setIsLoading(true);

    fetchPricePlans()
      .then((payloads) => {
        if (payloads) {
          const plan_ids = [];

          payloads.map((plan) => {
            plan_ids.push(plan.price_id);

            if (
              plan.is_bundled &&
              Array.isArray(plan?.bundles) &&
              plan.bundles.length
            ) {
              const bundleItems = plan.bundles || [];
              const recurringItems = bundleItems.filter(
                (bundle) => bundle.price_id && bundle.recurring
              );

              plan_ids.push(...recurringItems.map((bundle) => bundle.price_id));
            }
          });

          const coupon_code = searchParams.get("coupon_code");
          fetchCouponCodes(removeSpecialCharacters(coupon_code), plan_ids);
          initiateTracking();
        } else {
          setIsShowingButton(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [store, price_slug]);

  // ✅ Order Summary
  let itemsElements, bundleElements;
  let cart_amount = 0;
  let cart_currency = [];
  let cart_discounted_amount = 0;
  let cart_grand_total = 0;

  if (selectedPlans.length) {
    bundleElements = selectedPlans[0].bundles.map((plan, index) => {
      // find whether there is any coupon for this plan
      const validCoupon = appliedCoupons.find(
        (coupon) => coupon.plan.price_id === plan.price_id
      );

      const currency = plan?.currency.toUpperCase();

      let pricing = new PricingCalculator({
        amount: plan?.selling_price,
        ...taxSettings,
        ...validCoupon,
      }).calculate();

      let next_pricing = new PricingCalculator({
        amount: plan?.selling_price,
        ...taxSettings,
        ...validCoupon,
      }).calculate();

      // re-calculate the pricing
      if (validCoupon && validCoupon.next_discount_settings) {
        const { next_discount_type, next_percent_off, next_amount_off } =
          validCoupon;

        next_pricing = new PricingCalculator({
          amount: plan?.selling_price,
          ...taxSettings,
          percent_off: next_percent_off,
          amount_off: next_amount_off,
          discount_type: next_discount_type,
        }).calculate();
      }

      let trial_interval_string;
      if (validCoupon && [8].includes(validCoupon.permission)) {
        trial_interval_string =
          validCoupon.free_trial_interval_count > 1
            ? `${validCoupon.free_trial_interval}s`
            : validCoupon.free_trial_interval;

        pricing = new PricingCalculator({
          amount: plan?.selling_price,
          ...taxSettings,
          percent_off: 100,
          discount_type: "percentage",
        }).calculate();
      }

      const { amount, grand_total } = pricing;
      const { grand_total: next_grand_total } = next_pricing;

      return (
        <ListGroup.Item
          key={plan.price_id}
          className='border rounded-4 p-3 my-3 bg-light'
        >
          <h6>
            Bundle Item {index + 1}: {plan?.name} {/* Free Trial */}
            {validCoupon && [8].includes(validCoupon.permission) && (
              <span className='badge text-bg-success m-1 pp-brown' key={index}>
                <StarFill color='gold' />{" "}
                {validCoupon.free_trial_interval_count} {trial_interval_string}{" "}
                free trial
              </span>
            )}
          </h6>

          {/* Display selling price */}
          {(!validCoupon || ![8].includes(validCoupon?.permission)) && (
            <>
              <hr />
              <p className='text-body-secondary'>
                <Coin /> Usual Price: {currency} {formatPrice(amount)}
              </p>
            </>
          )}

          {/* Scenario 1: A recurring product */}
          {plan?.recurring && (
            <>
              {/* Display "XX-day/month/year subscription" */}
              {![8].includes(validCoupon?.permission) && (
                <p className='text-body-secondary'>
                  <Calendar3 /> {plan?.interval_count}-{plan?.interval}{" "}
                  subscription
                </p>
              )}

              {validCoupon && ![8].includes(validCoupon.permission) && (
                <>
                  {/* Display "FREE XX-day/month/year subscription" */}
                  {/* Do not show if it is zero and below */}
                  {validCoupon.extension_interval_count > 0 && (
                    <p className='fw-bolder green-text'>
                      <CaretUpFill /> Bonus: Free{" "}
                      {validCoupon.extension_interval_count}-
                      {validCoupon.extension_interval} subscription (for 1st
                      year only)
                    </p>
                  )}

                  {/* Initial Discount Settings */}
                  {/* Auto renewal @ ... */}
                  {!validCoupon.next_discount_settings && (
                    <p className='fw-bolder blue-text'>
                      <ArrowClockwise /> Auto-renewal at{" "}
                      <span>
                        {currency}{" "}
                        {formatPrice(
                          validCoupon.duration === "once" ? amount : grand_total
                        )}{" "}
                        {plan?.interval === "day" ? "dai" : plan?.interval}ly
                        until cancelled.
                      </span>
                    </p>
                  )}

                  {/* Next Discount Settings */}
                  {/* next_duration === "once" */}
                  {validCoupon.next_discount_settings &&
                    validCoupon.next_duration === "once" && (
                      <p className='fw-bolder blue-text'>
                        <ArrowClockwise /> Auto-renewal at{" "}
                        <span>
                          {currency} {formatPrice(next_grand_total)}
                        </span>
                        <span>, then, </span>
                        <span>
                          {currency} {formatPrice(plan?.selling_price)}{" "}
                          {plan?.interval === "day" ? "dai" : plan?.interval}ly
                          until cancelled.
                        </span>
                      </p>
                    )}

                  {/* next_duration === "forever" */}
                  {validCoupon.next_discount_settings &&
                    validCoupon.next_duration === "forever" && (
                      <p className='fw-bolder blue-text'>
                        <ArrowClockwise /> Auto-renewal at{" "}
                        <span>
                          {currency} {formatPrice(next_grand_total)}{" "}
                          {plan?.interval === "day" ? "dai" : plan?.interval}ly
                          until cancelled.
                        </span>
                      </p>
                    )}

                  {/* T&C of coupon */}

                  <p className='text-muted fst-italic small'>
                    * Terms and conditions apply for this coupon.
                  </p>
                </>
              )}

              {/* Auto renewal: Without coupon */}
              {!validCoupon && (
                <p className='fw-bolder blue-text'>
                  <ArrowClockwise /> Auto-renewal at{" "}
                  {
                    <span>
                      {currency} {formatPrice(grand_total)}{" "}
                      {plan?.interval === "day" ? "dai" : plan?.interval}ly
                      until cancelled.
                    </span>
                  }
                </p>
              )}
            </>
          )}

          {/* Display "One-time payment" */}
          {!plan?.recurring && ![8].includes(validCoupon?.permission) && (
            <p className='text-body-secondary'>
              <Calendar3 /> One-time payment
            </p>
          )}
        </ListGroup.Item>
      );
    });

    itemsElements = selectedPlans.map((plan, index) => {
      // find whether there is any coupon for this plan
      const validCoupon = appliedCoupons.find(
        (coupon) => coupon.plan.price_id === plan.price_id
      );

      const currency = plan?.currency.toUpperCase();

      let pricing = new PricingCalculator({
        amount: plan?.selling_price,
        ...taxSettings,
        ...validCoupon,
      }).calculate();

      let next_pricing = new PricingCalculator({
        amount: plan?.selling_price,
        ...taxSettings,
        ...validCoupon,
      }).calculate();

      // re-calculate the pricing
      if (validCoupon && validCoupon.next_discount_settings) {
        const { next_discount_type, next_percent_off, next_amount_off } =
          validCoupon;

        next_pricing = new PricingCalculator({
          amount: plan?.selling_price,
          ...taxSettings,
          percent_off: next_percent_off,
          amount_off: next_amount_off,
          discount_type: next_discount_type,
        }).calculate();
      }

      let trial_interval_string;
      if (validCoupon && [8].includes(validCoupon.permission)) {
        trial_interval_string =
          validCoupon.free_trial_interval_count > 1
            ? `${validCoupon.free_trial_interval}s`
            : validCoupon.free_trial_interval;

        pricing = new PricingCalculator({
          amount: plan?.selling_price,
          ...taxSettings,
          percent_off: 100,
          discount_type: "percentage",
        }).calculate();
      }

      const { amount, discount_amount, grand_total } = pricing;
      const { grand_total: next_grand_total } = next_pricing;

      // ✅ Calculate total discounted amount and grand amount for cart
      cart_currency.push(currency);
      cart_amount += amount;
      cart_discounted_amount += discount_amount;
      cart_grand_total += grand_total;

      return (
        <ListGroup.Item key={plan.price_id}>
          {plan?.thumbnail && (
            <img
              src={`${process.env.REACT_APP_IMAGES_ENDPOINT}/${plan?.thumbnail}`}
              className='mb-3'
              width={"100%"}
            />
          )}

          <h6 className='fw-bold'>
            {/* Cart Item */}
            {index + 1}. {plan?.name}
            {/* Free Trial */}
            {validCoupon && [8].includes(validCoupon.permission) && (
              <span className='badge text-bg-success m-1 pp-brown' key={index}>
                <StarFill color='gold' />{" "}
                {validCoupon.free_trial_interval_count} {trial_interval_string}{" "}
                free trial
              </span>
            )}
            {/* Features */}
            {plan?.features && plan.features.length > 0 && (
              <button type='button' className='btn'>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Popover className='my-popover'>
                      <Popover.Header as='h3'>Features</Popover.Header>
                      <Popover.Body>
                        {plan.features.map((feature, index) => (
                          <ListGroup key={index}>✅ {feature}</ListGroup>
                        ))}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <InfoCircle />
                </OverlayTrigger>
              </button>
            )}
          </h6>

          {/* Display selling price */}
          {(!validCoupon || ![8].includes(validCoupon?.permission)) && (
            <p className='text-body-secondary'>
              <Coin /> Usual Price: {currency} {formatPrice(amount)}
            </p>
          )}

          {validCoupon && ![8].includes(validCoupon.permission) && (
            <>
              {/* Condition: Applied coupon code */}
              {/* Display "Discount applied: $ xxx.xx" */}
              {discount_amount > 0 && (
                <p className='fw-bolder text-danger'>
                  <CaretDownFill /> Discount applied: {currency}{" "}
                  {formatPrice(discount_amount)}
                </p>
              )}
            </>
          )}

          {/* Bundles */}
          {plan?.is_bundled && <>{bundleElements}</>}

          {/* Scenario 1: A recurring product */}
          {plan?.recurring && (
            <>
              {/* Display "XX-day/month/year subscription" */}
              {![8].includes(validCoupon?.permission) && (
                <p className='text-body-secondary'>
                  <Calendar3 /> {plan?.interval_count}-{plan?.interval}{" "}
                  subscription
                </p>
              )}

              {validCoupon && ![8].includes(validCoupon.permission) && (
                <>
                  {/* Display "FREE XX-day/month/year subscription" */}
                  {/* Do not show if it is zero and below */}
                  {validCoupon.extension_interval_count > 0 && (
                    <p className='fw-bolder green-text'>
                      <CaretUpFill /> Bonus: Free{" "}
                      {validCoupon.extension_interval_count}-
                      {validCoupon.extension_interval} subscription (for 1st
                      year only)
                    </p>
                  )}

                  {/* Initial Discount Settings */}
                  {/* Auto renewal @ ... */}
                  {!validCoupon.next_discount_settings && (
                    <p className='fw-bolder blue-text'>
                      <ArrowClockwise /> Auto-renewal at{" "}
                      <span>
                        {currency}{" "}
                        {formatPrice(
                          validCoupon.duration === "once" ? amount : grand_total
                        )}{" "}
                        {plan?.interval === "day" ? "dai" : plan?.interval}ly
                        until cancelled.
                      </span>
                    </p>
                  )}

                  {/* Next Discount Settings */}
                  {/* next_duration === "once" */}
                  {validCoupon.next_discount_settings &&
                    validCoupon.next_duration === "once" && (
                      <p className='fw-bolder blue-text'>
                        <ArrowClockwise /> Auto-renewal at{" "}
                        <span>
                          {currency} {formatPrice(next_grand_total)}
                        </span>
                        <span>, then, </span>
                        <span>
                          {currency} {formatPrice(plan?.selling_price)}{" "}
                          {plan?.interval === "day" ? "dai" : plan?.interval}ly
                          until cancelled.
                        </span>
                      </p>
                    )}

                  {/* next_duration === "forever" */}
                  {validCoupon.next_discount_settings &&
                    validCoupon.next_duration === "forever" && (
                      <p className='fw-bolder blue-text'>
                        <ArrowClockwise /> Auto-renewal at{" "}
                        <span>
                          {currency} {formatPrice(next_grand_total)}{" "}
                          {plan?.interval === "day" ? "dai" : plan?.interval}ly
                          until cancelled.
                        </span>
                      </p>
                    )}

                  {/* T&C of coupon */}
                  <p className='text-muted fst-italic small'>
                    * Terms and conditions apply for this coupon.
                  </p>
                </>
              )}

              {/* Auto renewal: Without coupon */}
              {!validCoupon && (
                <p className='fw-bolder blue-text'>
                  <ArrowClockwise /> Auto-renewal at{" "}
                  {
                    <span>
                      {currency} {formatPrice(grand_total)}{" "}
                      {plan?.interval === "day" ? "dai" : plan?.interval}ly
                      until cancelled.
                    </span>
                  }
                </p>
              )}
            </>
          )}
        </ListGroup.Item>
      );
    });
  }

  // ✅ Pricing summary
  let pricingElements = (
    <div className='me-auto'>
      <table className='table table-responsive'>
        <tbody>
          <tr>
            <td>Currency</td>
            <td>:</td>
            <td>{String(cart_currency).toUpperCase()}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>:</td>
            <td>$ {formatPrice(cart_amount)}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td>:</td>
            <td>
              {cart_discounted_amount > 0 ? "-" : ""} ${" "}
              {formatPrice(cart_discounted_amount)}
            </td>
          </tr>
          <tr className='table-secondary fw-bold'>
            <td>Final amount</td>
            <td>:</td>
            <td>≈ $ {formatPrice(cart_grand_total)} </td>
          </tr>
        </tbody>
      </table>

      {selectedPlans.length >= 1 && cart_grand_total < 0 && (
        <p className='text-danger text-center'>Oops! Pricing error...</p>
      )}
    </div>
  );

  // ✅ Apply and remove coupon code
  const couponCodeChangeHandler = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setCouponCode(value ? removeSpecialCharacters(value.toUpperCase()) : "");
  };

  const applyCouponCodeHandler = (e) => {
    e.preventDefault();
    if (!couponCode) return;
    const plan_ids = [];

    selectedPlans.map((plan) => {
      plan_ids.push(plan.price_id);

      if (
        plan.is_bundled &&
        Array.isArray(plan?.bundles) &&
        plan.bundles.length
      ) {
        const bundleItems = plan.bundles || [];
        const recurringItems = bundleItems.filter(
          (bundle) => bundle.price_id && bundle.recurring
        );

        plan_ids.push(...recurringItems.map((bundle) => bundle.price_id));
      }
    });

    fetchCouponCodes(removeSpecialCharacters(couponCode), plan_ids);
  };

  const removeCouponCodeHandler = (e) => {
    e.preventDefault();
    setCouponCode("");
    setAppliedCoupons([]);
  };

  return (
    <div className='container'>
      <div className='row mt-5'>
        <Link to={`/stores/${store}`} className='text-center'>
          <Logo />
        </Link>
      </div>

      <div className='row g-5 justify-content-center m-0 p-0'>
        <div className='col-lg-5 col-md-12 order-md-last m-4 p-0'>
          {/* Order summary */}
          <div className='border bg-white p-3 rounded-4'>
            <h4 className='mb-3'>Order summary</h4>
            <hr />

            {!isLoading && itemsElements}

            {!isLoading && isShowingButton && (
              <div className='text-center'>
                <Link
                  to={`/stores/${store}/`}
                  className='text-center text-decoration-none btn btn-dark fw-bold'
                >
                  Click here to find your favourite memberships!
                </Link>
              </div>
            )}
          </div>

          {/* Pricing summary */}
          <div className='border bg-white p-3 rounded-4 my-3'>
            <h4 className='mb-3'>Pricing summary</h4>
            <hr />

            {pricingElements}
          </div>

          {/* Coupon Code */}
          <div className='border bg-white p-3 rounded-4 my-3'>
            <p>Have a coupon code?</p>

            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                placeholder='Key in the coupon code'
                value={couponCode}
                onChange={couponCodeChangeHandler}
                disabled={appliedCoupons.length}
              />
              {!appliedCoupons.length ? (
                <button
                  type='submit'
                  className='btn btn-secondary'
                  disabled={isAddingCoupon || !couponCode}
                  onClick={applyCouponCodeHandler}
                >
                  {isAddingCoupon ? (
                    <BasicSpinner variant='white' small />
                  ) : (
                    <CheckLg />
                  )}
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn btn-secondary'
                  onClick={removeCouponCodeHandler}
                >
                  <XLg />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Payment Form */}
        <div className='col-lg-6 col-md-12 me-lg-1 m-4 p-0'>
          <div className='shadow border bg-white p-3 rounded-4'>
            <h4 className='mb-3'>Payment information</h4>
            <hr />

            <PaymentForm
              cart={selectedPlans}
              coupons={appliedCoupons}
              removeCoupons={removeNotPermittedCoupons}
              utm={extractUTM()}
              grand_total={cart_grand_total}
              disabled={cart_grand_total < 0 || isNaN(cart_grand_total)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCheckout;
